<template>
  <div class="card card-custom">
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder">{{ $t('booking.title') }}
          <span class="text-muted pt-2 font-size-sm d-block">{{ $t('booking.count_booking', {n: bookingsTotalCount}) }}</span>
        </h3>
      </div>
      <div class="card-toolbar">
        <router-link to="/services/rooms"class="btn btn-light btn-hover-primary mr-3">
          <font-awesome-icon class="mr-1" icon="plus"></font-awesome-icon>
          {{ $t('booking.new_booking') }}
        </router-link>
      </div>
    </div>
    <div v-visibility-change="visibilityChange" class="card-body" >
      <div class="row">
          <div class="py-3">
            <form>
              <div class="form-row">
                <div class="col">
                    <label>{{ $t('booking.location') }}</label>
                    <select class="form-control" v-model="filter.location_id">
                      <option value="">{{$t('common.all')}}</option>
                      <option v-for="location in locationList" :value="location.id">{{ location.name }}</option>
                    </select>
                </div>
                <div class="col">
                    <label>{{ $t('booking.filter_status') }}</label>
                    <select class="form-control" v-model="filter.status">
                      <option value="">{{$t('common.all')}}</option>
                      <option v-for="status in statuses" :value="status.id">{{ $t(`booking.status.${status.name}`) }}</option>
                    </select>
                </div>
                <div class="col">
                  <label>{{ $t('booking.started_at') }}</label>
                  <date-range-picker
                      ref="picker"
                      :show-dropdowns="true"
                      :linked-calendars="true"
                      :close-on-esc="true"
                      :ranges="false"
                      v-model="createRange"
                      @update="updateCreateRange"
                      control-container-class=""
                      :locale-data="$t('data-range-piker')"
                  >
                    <div slot="input" slot-scope="data" style="min-width:220px;" class="input-group">
                      <div v-if="data.startDate" type="text" class="form-control"
                           placeholder="Recipient's username" aria-label="Recipient's username"
                           aria-describedby="basic-addon2">{{ data.startDate | formatDate }} - {{ data.endDate | formatDate }}</div>
                      <div v-else type="text" class="form-control"
                           placeholder="Recipient's username" aria-label="Recipient's username"
                           aria-describedby="basic-addon2"></div>
                      <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon2"><i class="flaticon-event-calendar-symbol"></i></span>
                      </div>
                    </div>
                    <div slot="footer" slot-scope="data" class="drp-buttons">
                      <span data-v-4f8eb193="" class="drp-selected">{{data.rangeText}}</span>
                      <button @click="clickCreateRangeCancel(data)" type="button"
                              class="cancelBtn btn btn-sm btn-secondary">{{data.locale.cancelLabel}} </button>
                      <button @click="data.clickApply" v-if="!data.in_selection" type="button"
                              class="applyBtn btn btn-sm btn-success">{{data.locale.applyLabel}} </button>
                    </div>
                  </date-range-picker>
                </div>
                <div class="col">
                  <label>{{ $t('booking.booking_period') }}</label>
                  <date-range-picker
                      ref="picker"
                      :show-dropdowns="true"
                      :linked-calendars="true"
                      :close-on-esc="true"
                      :ranges="false"
                      v-model="dateRange"
                      @update="updateDataRange"
                      control-container-class=""
                      :locale-data="$t('data-range-piker')"
                  >
                    <div slot="input" slot-scope="data" style="min-width:220px;" class="input-group">
                      <div v-if="data.startDate" type="text" class="form-control"
                           placeholder="Recipient's username" aria-label="Recipient's username"
                           aria-describedby="basic-addon2">{{ data.startDate | formatDate }} - {{ data.endDate | formatDate }}</div>
                      <div v-else type="text" class="form-control"
                           placeholder="Recipient's username" aria-label="Recipient's username"
                           aria-describedby="basic-addon2"></div>
                      <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon2"><i class="flaticon-event-calendar-symbol"></i></span>
                      </div>
                    </div>
                    <div slot="footer" slot-scope="data" class="drp-buttons">
                      <span data-v-4f8eb193="" class="drp-selected">{{data.rangeText}}</span>
                      <button @click="clickDataRangeCancel(data)" type="button"
                              class="cancelBtn btn btn-sm btn-secondary">{{data.locale.cancelLabel}} </button>
                      <button @click="data.clickApply" v-if="!data.in_selection" type="button"
                              class="applyBtn btn btn-sm btn-success">{{data.locale.applyLabel}} </button>
                    </div>
                  </date-range-picker>
                </div>
                <div class="col">
                  <label>{{ $t('booking.filter_sources') }}</label>
                  <select class="form-control" v-model="filter.sources">
                    <option value="">{{$t('common.all')}}</option>
                    <option v-for="source in sources" :value="source.id">{{ $t(`booking.sources.${source.name}`) }}</option>
                  </select>
                </div>
                <div class="col">
                  <label>{{ $t('booking.filter_payment_ids') }}</label>
                  <select class="form-control" v-model="filter.payment_method_id">
                    <option value="">{{$t('common.all')}}</option>
                    <option v-for="payment_id in payment_ids" :value="payment_id.id">{{ $t(`booking.payment_id.${payment_id.name}`) }}</option>
                  </select>
                </div>
              </div>
            </form>
          </div>
        </div>
      <!--begin: Datatable-->
      <core-datatable :loaded="loaded" v-bind:columns.sync=columns :showFooter="true" :items="bookingsList"
                      v-bind:filter.sync="filter" :totalCount="bookingsTotalCount">
        <template #resource="{ item }">
          <span class="font-weight-bolder">{{item.room.name}}</span><br>
          <span class="text-muted">{{item.room.address}}</span>
        </template>
        <template #ts="{ item }">
          {{ null | formatDateInterval(item.ts_start, item.ts_end, getTimezone(item.location.id)) }}
        </template>
        <template #creator="{ item }">
          <span class="font-weight-bolder">{{item.user.fio}}</span><br>
          <span v-if="item.company_id" class="text-muted">{{$t('booking.issued_on')}} {{getCompanyName(item)}}</span>
          <span v-else class="text-muted">{{$t('booking.issued_on')}} {{item.user.fio}}</span>
        </template>
        <template #sources="{ item, deepFind, getValuesItem, column }">
          <span v-if="item.source">
            {{ $t(`booking.sources.${getValuesItem(item.source, column).name}`) !== 'booking.sources.undefined'
              ? $t(`booking.sources.${getValuesItem(item.source, column).name}`) : item.source }}
          </span>
          <span v-else> </span>
        </template>
        <template #created_at="{ item }">
          <span v-if="item.created_at">{{item.created_at | formatDate}}</span>
          <span v-else>-</span>
        </template>
        <template #status="{ item, deepFind, getValuesItem, column }">
          <span class="label label-lg label-inline"
                :class="'label-light-' + getValuesItem(item.status, column).class">
            {{$t(`booking.status.${getValuesItem(item.status, column).name}`)}}
          </span>
        </template>
        <template #payment_id="{ item, deepFind, getValuesItem, column }">
          <template v-if="item.status === 1 || item.status === 5">
            <span v-if="item.order && item.order.payment_id">
              <strong>{{$t(`booking.payment_id.${getValuesItem(item.order.payment_id, column).name}`)}}</strong>
            </span>
            <span v-else><strong>{{$t(`booking.payment_id.Spass`)}}</strong></span>
          </template>
          <span v-else>-</span>
        </template>
        <template #action="{ item }">
          <a v-if="isCanPaided(item) && item.location.is_online_payment_enabled" class="btn btn-sm btn-icon btn-light-success mr-2"
             target="_blank" :href="item.guid | payUrl">
            <font-awesome-icon class="icon-md" icon="credit-card"></font-awesome-icon>
          </a>
          <a v-if="isCanCanceled(item)" class="btn btn-sm btn-icon btn-light-danger mr-2"
             @click="showDialogCancelModal(item)">
            <font-awesome-icon class="icon-md" icon="ban"></font-awesome-icon>
          </a>
          <router-link v-if="isCanProlong(item)"
                       :to="`/services/rooms?id=${item.resource_id}`"
                       class="btn btn-sm btn-icon btn-light-primary mr-2">
            <font-awesome-icon class="icon-md" icon="sync-alt"></font-awesome-icon>
          </router-link>
        </template>
      </core-datatable>
      <!--end: Datatable-->
    </div>

    <b-modal v-model="dialogCancelModal" size="md" @ok="goCancel"
             :title="$t('booking.cancel_booking')" :ok-title="$t('booking.yes_cancel')" :cancel-title="$t('booking.back')">
      <div class="d-flex flex-column align-items-center">
        <div class="symbol symbol-50 symbol-light-danger mb-4 flex-shrink-0">
          <div class="symbol-label">
            <font-awesome-icon icon="ban" class="text-danger icon-lg"/>
          </div>
        </div>
        <div class="font-weight-bolder">{{ $t('booking.cancel_you_booking') }}</div>
      </div>
    </b-modal>
  </div>
</template>


<script>
import {mapMutations, mapGetters} from 'vuex'
// import moment from 'moment'
import moment from 'moment-timezone'
import api from '@/plugins/api'

// https://innologica.github.io/vue2-daterange-picker/#props
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { Component, Vue } from 'vue-property-decorator'

let cookie = require('vue-cookie');
let bg = require('@/assets/bg/bg-4.jpg')

export default {
  components: { DateRangePicker },
  computed: {
    ...mapGetters({
      bookingsList: 'bookingsOrder/list',
      bookingsPage: 'bookingsOrder/page',
      bookingsPerPage: 'bookingsOrder/perPage',
      bookingsTotalCount: 'bookingsOrder/totalCount',
      locationList: 'locationOrder/list',
      loaded: 'bookingsOrder/loaded'
    })
  },
  data() {
    return {
      paidForm: '',
      dialogCancelModal: false,
      dialogPaidModal: false,
      createRange: {},
      dateRange: {},
      loading: false,
      valid: false,
      error: null,
      selected: {},
      statuses: [
        { id: 2, name: 'Remove' },
        { id: 1, name: 'Active' },
        { id: 3, name: 'Hold' },
        { id: 4, name: 'Canceled' },
        { id: 5, name: 'Used' },
      ],
      payment_ids: [
        { id: 1, name: 'Cash' },
        { id: 2, name: 'Card' },
        { id: 3, name: 'Invoice' },
        { id: 4, name: 'Credit' },
        { id: 5, name: 'Free' },
        { id: 6, name: 'Card online' },
        { id: 8, name: 'Deposit' },
        { id: 0, name: 'Spass' },
      ],
      columns: [
        { text: 'booking.columns.order', value: 'id', key: 'id' },
        { text: 'booking.columns.meeting_room_location', slot: 'resource', key: 'resource', sort: false },
        { text: 'booking.columns.booking_period', slot: 'ts', key: 'ts_start', sort: false },
        { text: 'booking.columns.creator', slot: 'creator', key: 'creator', sort: false },
        { text: 'booking.columns.sources', slot: 'sources', key: 'sources', values: [
            { id: 'panel', name: 'Admin_panel' },
            { id: 'widget', name: 'Widget' },
            { id: 'lk', name: 'Personal_area' },
            { id: 'mobile', name: 'Mobile_app' },
          ]},
        { text: 'booking.columns.created_at', slot: 'created_at', key: 'created_at' },
        { text: 'booking.columns.status', slot: 'status', key: 'status', values: [
            { id: 2, name: 'Remove', class: 'danger' },
            { id: 1, name: 'Active', class: 'success' },
            { id: 3, name: 'Hold', class: 'info' },
            { id: 4, name: 'Canceled', class: 'danger' },
            { id: 5, name: 'Used', class: 'dark' },
          ]},
        { text: 'booking.columns.payment_id', slot: 'payment_id', key: 'payment_id', values: [
            { id: 1, name: 'Cash' },
            { id: 2, name: 'Card' },
            { id: 3, name: 'Invoice' },
            { id: 4, name: 'Credit' },
            { id: 5, name: 'Free' },
            { id: 6, name: 'Card online' },
            { id: 8, name: 'Deposit' },
            { id: 0, name: 'Spass' },
          ]},
        { text: 'booking.columns.action', slot: 'action', sort: false },
      ],
      sources: [
        { id: 'panel', name: 'Admin_panel' },
        { id: 'widget', name: 'Widget' },
        { id: 'lk', name: 'Personal_area' },
        { id: 'mobile', name: 'Mobile_app' },
      ],
      filter: {
        sort: [],
        location_id: '',
        status: '',
        sources: '',
        payment_method_id: '',
        ts_created_from: '',
        ts_created_to: '',
        from: '',
        to: '',
        page: 1,
        'per-page': 20,
      }
    }
  },
  mounted() {
    this.$store.dispatch('locationOrder/GetData')
    this.fetch()
  },
  watch: {
    columns: {
      handler() {
        this.fetch()
      },
      deep: true,
    },
    filter: {
      handler() {
        this.fetch()
      },
      deep: true,
    },
  },
  methods: {
    getTimezone(locationId) {
      const location = this.locationList.find(item => {
        return item.id === locationId;
      })
      return location && location.city ? location.city.timezone : 'UTC';
    },
    getCompanyName(item) {
      let companyName = item.user.companies.filter(company => {
        return company.id === item.company_id;
      })
      if (companyName.length > 0) return companyName[0].name;
      else return item.user.fio;
    },
    clickCreateRangeCancel(data) {
      this.createRange = {};
      data.rangeText = '';
      this.filter.ts_created_from = '';
      this.filter.ts_created_to = '';
      data.clickCancel();
    },
    clickDataRangeCancel(data) {
      this.dateRange = {}
      data.rangeText = ''
      this.filter.from  = ''
      this.filter.to  = ''
      data.clickCancel()
    },
    updateCreateRange(params) {
      this.filter.ts_created_from = moment.unix(params.startDate).format('X') / 1000;
      this.filter.ts_created_to = moment.unix(params.endDate).format('X') / 1000;
    },
    updateDataRange(params) {
      this.filter.from  = moment.unix(params.startDate).format('X') / 1000
      this.filter.to  = moment.unix(params.endDate).format('X') / 1000
    },
    fetch() {
      for (let i in this.columns) {
        switch(this.columns[i].sort) {
          case 1:
            this.filter.sort = this.columns[i].key
            break;
          case 2:
            this.filter.sort = '-'+this.columns[i].key
            break;
        }
      }
      this.$store.dispatch('bookingsOrder/GetData', this.filter)
    },
    visibilityChange(evt, hidden){
      if (!hidden) {
        this.fetch();
      }
    },
    calcTotalCostForSelectedService() {
      let total = 0
      for (let i in this.selected.products) {
        total = total + (this.selected.products[i].cost * this.selected.products[i].quantity)
      }
      return total
    },
    showInfoModal(selected) {
      this.selected = selected
      this.showInfo = !this.showInfo
    },
    showDialogCancelModal(selected) {
      this.selected = selected
      this.dialogCancelModal = true
    },
    goCancel() {
      let self = this
      api.delete('v2/bookings/' + this.selected.id).then(responce => {
        self.$store.dispatch('bookingsOrder/GetData', self.filter);
        this.dialogCancelModal = false
      })
    },
    isCanCanceled(item) {
      return item.can_be_canceled && item.can_canceled_until > moment().format('X');

    },
    isCanProlong(item) {
      return !!item.can_be_extended;
    },
    isCanPaided(item) {
      return item.status === 3;
    },
  }
}
</script>

<style>

</style>
